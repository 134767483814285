<template>
    <div>
        <sub-header :backButton="true" backRedirectionUrl="/clients-list">
            <template slot="actions">
                <div class="flex gap-4" v-if="clientId">
                    <Button type="primary" @click="showCheckLinkModal" :text="$t('pages.tenant_client.assign_checks')">
                        <!-- <font-awesome-icon class="text-base mr-4" :icon="buttonIcon ? buttonIcon : 'plus'" /> -->
                    </Button>
                    <Button type="primary" @click="showPackageLinkModal" :text="$t('pages.tenant_client.assign_packages')">
                        <!-- <font-awesome-icon class="text-base mr-4" :icon="buttonIcon ? buttonIcon : 'plus'" /> -->
                    </Button>
                </div>
            </template>
        </sub-header>

        <div class="flex flex-1 flex-col flex-grow max-h-full max-w-full overflow-auto scroll-bar">
            <div v-if="isLoading" class="flex py-10 min-w-full shadow flex-col gap-4 bg-card-bg shadow items-center justify-center rounded-md">
                <loader />
                {{ $t('general.loading') }}
            </div>
            <div v-else class="bg-card-bg rounded-2xl shadow p-4 flex flex-col gap-4">
                <FormulateForm #default="{ hasErrors }">
                    <div class="">
                        <div class="form w-full grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-4 lg:gap-2">
                            <!-- wide:gap-6 -->
                            <FormulateInput class="w-full" 
                            :label="$t('pages.tenant_client.client_name')" :placeholder="$t('pages.tenant_client.client_name')" type="text" validation="required" v-model="client.name" showRequired outer-class="mb-4 w-full" input-class="border input-bordered rounded px-3 py-2 leading-none focus:border-primary-focus outline-none input border-box w-full mb-1" label-class="font-medium text-sm" help-class="text-xs mb-1 text-gray-600" error-class="text-red-700 text-xs mb-1" />
                            <!-- v-if="!clientId" -->
                            <FormulateInput class="w-full" 
                            :label="$t('pages.tenant_client.first_admin_name')" :placeholder="$t('pages.tenant_client.first_admin_name')"
                            :disabled="clientId" type="text" validation="required" v-model="client.admin.user_name" outer-class="mb-4 w-full" input-class="border input-bordered rounded px-3 py-2 leading-none focus:border-primary-focus outline-none input border-box w-full mb-1" label-class="font-medium text-sm" help-class="text-xs mb-1 text-gray-600" error-class="text-red-700 text-xs mb-1" />

                            <FormulateInput class="w-full" 
                            :label="$t('pages.tenant_client.telephone')" :placeholder="$t('pages.tenant_client.telephone')"
                            type="tel" validation="required" v-model="client.telephone" outer-class="mb-4 w-full" input-class="border input-bordered rounded px-3 py-2 leading-none focus:border-primary-focus outline-none input border-box w-full mb-1" label-class="font-medium text-sm" help-class="text-xs mb-1 text-gray-600" error-class="text-red-700 text-xs mb-1" />
                            <FormulateInput class="w-full" 
                            :label="$t('pages.tenant_client.vat_reg')" :placeholder="$t('pages.tenant_client.vat_reg')"
                            type="text" v-model="client.vat_registration_no" outer-class="mb-4 w-full" input-class="border input-bordered rounded px-3 py-2 leading-none focus:border-primary-focus outline-none input border-box w-full mb-1" label-class="font-medium text-sm" help-class="text-xs mb-1 text-gray-600" error-class="text-red-700 text-xs mb-1" />
                            <FormulateInput
                                class="w-full"
                                :label="$t('pages.tenant_client.retention_period_should_be_in_days')" 
                                :placeholder="$t('pages.tenant_client.retention_period')"
                                type="text"
                                min="0"
                                validation="required|number|min:1|max:30000|number|matches:/^[+]?\d+$/"
                                :validation-messages="{
                                    matches: $t('pages.tenant_client.retention_valid_values'),
                                }"
                                v-model="client.retention_period"
                                outer-class="mb-4 w-full"
                                input-class="border input-bordered rounded px-3 py-2 leading-none focus:border-primary-focus outline-none input border-box w-full mb-1"
                                label-class="font-medium text-sm"
                                help-class="text-xs mb-1 text-gray-600"
                                error-class="text-red-700 text-xs mb-1"
                            />

                            <FormulateInput class="w-full" 
                                :label="$t('pages.tenant_client.onboarding_date')" 
                                :placeholder="$t('pages.tenant_client.onboarding_date')"
                                type="date" readonly v-model="client.createdAt" outer-class="mb-4 w-full" input-class="border input-bordered rounded px-3 py-2 leading-none focus:border-primary-focus outline-none input border-box w-full mb-1" label-class="font-medium text-sm" help-class="text-xs mb-1 text-gray-600" error-class="text-red-700 text-xs mb-1" />

                            <!-- // address block  -->
                            <div class="p-4 col-span-3 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mb-4 rounded-lg border border-gray-300 shadow" v-if="client && client.address">
                                <h3 class="col-span-full text-lg font-medium text-gray-800">
                                    {{ $t('pages.tenant_client.address') }}
                                </h3>

                                <FormulateInput class="w-full" :label="$t('pages.tenant_client.line_1')" 
                                :placeholder="$t('pages.tenant_client.line_1')" type="text" v-model="client.address.line_1" showRequired outer-class="mb-4 w-full" input-class="border input-bordered rounded px-3 py-2 leading-none focus:border-primary-focus outline-none input border-box w-full mb-1" label-class="font-medium text-sm" help-class="text-xs mb-1 text-gray-600" error-class="text-red-700 text-xs mb-1 " validation="required" />
                                
                                <FormulateInput class="w-full" :label="$t('pages.tenant_client.line_2')" 
                                :placeholder="$t('pages.tenant_client.line_2')" type="text" v-model="client.address.line_2" showRequired outer-class="mb-4 w-full" input-class="border input-bordered rounded px-3 py-2 leading-none focus:border-primary-focus outline-none input border-box w-full mb-1" label-class="font-medium text-sm" help-class="text-xs mb-1 text-gray-600" error-class="text-red-700 text-xs mb-1" />
                                
                                <!-- // city  -->
                                <FormulateInput class="w-full" :label="$t('pages.tenant_client.city')" 
                                :placeholder="$t('pages.tenant_client.city')" type="text" v-model="client.address.city" showRequired outer-class="mb-4 w-full" input-class="border input-bordered rounded px-3 py-2 leading-none focus:border-primary-focus outline-none input border-box w-full mb-1" label-class="font-medium text-sm" help-class="text-xs mb-1 text-gray-600" error-class="text-red-700 text-xs mb-1" validation="required" />
                                
                                <!-- // country  -->
                                <FormulateInput class="w-full" :label="$t('pages.tenant_client.country')" 
                                :placeholder="$t('pages.tenant_client.country')" type="text" v-model="client.address.country" outer-class="mb-4 w-full" input-class="border input-bordered rounded px-3 py-2 leading-none focus:border-primary-focus outline-none input border-box w-full mb-1" label-class="font-medium text-sm" help-class="text-xs mb-1 text-gray-600" error-class="text-red-700 text-xs mb-1" validation="required" />
                                
                                <!-- zip code -->
                                <FormulateInput
                                    class="w-full"
                                    :label="$t('pages.tenant_client.postcode')" 
                                    :placeholder="$t('pages.tenant_client.postcode')"
                                    type="text"
                                    validation="required|matches:/^[a-zA-Z0-9\s\-]+$/"
                                    :validation-messages="{ matches: 'Only characters supported are alphanumeric, - and spaces' }"
                                    v-model="client.address.zip_code"
                                    outer-class="mb-4 w-full"
                                    input-class="border input-bordered rounded px-3 py-2 leading-none focus:border-primary-focus outline-none input border-box w-full mb-1"
                                    label-class="font-medium text-sm"
                                    help-class="text-xs mb-1 text-gray-600"
                                    error-class="text-red-700 text-xs mb-1"
                                />
                            </div>

                            <FormulateInput class="w-full" 
                                :label="$t('pages.tenant_client.max_users')" 
                                :placeholder="$t('pages.tenant_client.max_users')"
                                type="number" min="0" validation="required|number|min:0,number" v-model="client.max_users" outer-class="mb-4 w-full" input-class="border input-bordered rounded px-3 py-2 leading-none focus:border-primary-focus outline-none input border-box w-full mb-1" label-class="font-medium text-sm" help-class="text-xs mb-1 text-gray-600" error-class="text-red-700 text-xs mb-1" />
                            
                            <FormulateInput class="w-full" 
                                :label="$t('pages.tenant_client.max_candidates')" 
                                :placeholder="$t('pages.max_candidate_number.country')"
                                type="number" min="0" validation="required|number|min:0,number" v-model="client.max_candidate" outer-class="mb-4 w-full" input-class="border input-bordered rounded px-3 py-2 leading-none focus:border-primary-focus outline-none input border-box w-full mb-1" label-class="font-medium text-sm" help-class="text-xs mb-1 text-gray-600" error-class="text-red-700 text-xs mb-1" />
                            
                            <FormulateInput
                                class="w-full"
                                :label="$t('pages.tenant_client.client_prefix')" 
                                :placeholder="$t('pages.tenant_client.client_prefix_placeholder')"
                                type="text"
                                :disabled="clientId"
                                validation="required|min:3,length"
                                @input="updatePrefix"
                                :validation-messages="{ matches: 'Prefix must be lowercase.' }"
                                v-model="client.prefix"
                                outer-class="mb-4 w-full"
                                input-class="border input-bordered rounded px-3 py-2 leading-none focus:border-primary-focus outline-none input border-box w-full mb-1"
                                label-class="font-medium text-sm"
                                help-class="text-xs mb-1 text-gray-600"
                                error-class="text-red-700 text-xs mb-1"
                            />
                            <!-- <FormulateInput class="w-full" label="Main User Name" placeholder="Main User Name" type="text" validation="required" v-model="client.admin.user_name" showRequired outer-class="mb-4 w-full" input-class="border input-bordered rounded px-3 py-2 leading-none focus:border-primary-focus outline-none input border-box w-full mb-1" label-class="font-medium text-sm" help-class="text-xs mb-1 text-gray-600" error-class="text-red-700 text-xs mb-1" /> -->
                            <!-- v-if="!clientId" -->
                            <FormulateInput class="w-full" 
                                :label="$t('pages.tenant_client.main_contact_no')" 
                                :placeholder="$t('pages.tenant_client.main_contact_no')"
                                type="text" validation="required" v-model="client.primary_contact_no" outer-class="mb-4 w-full" input-class="border input-bordered rounded px-3 py-2 leading-none focus:border-primary-focus outline-none input border-box w-full mb-1" label-class="font-medium text-sm" help-class="text-xs mb-1 text-gray-600" error-class="text-red-700 text-xs mb-1" />

                            <FormulateInput class="w-full" 
                                :label="$t('pages.tenant_client.main_contact_email')" 
                                :placeholder="$t('pages.tenant_client.main_contact_email')"
                                type="email" :disabled="clientId" validation="required|email" v-model="client.admin.email" outer-class="mb-4 w-full" input-class="border input-bordered rounded px-3 py-2 leading-none focus:border-primary-focus outline-none input border-box w-full mb-1" label-class="font-medium text-sm" help-class="text-xs mb-1 text-gray-600" error-class="text-red-700 text-xs mb-1" />

                            <FormulateInput class="w-full" 
                                :label="$t('pages.tenant_client.main_user_position')" 
                                :placeholder="$t('pages.tenant_client.main_user_position')"
                                type="text" validation="required" v-model="client.primary_user_position" showRequired outer-class="mb-4 w-full" input-class="border input-bordered rounded px-3 py-2 leading-none focus:border-primary-focus outline-none input border-box w-full mb-1" label-class="font-medium text-sm" help-class="text-xs mb-1 text-gray-600" error-class="text-red-700 text-xs mb-1" />

                            <FormulateInput class="w-full" 
                                :label="$t('pages.tenant_client.notes')" 
                                :placeholder="$t('pages.tenant_client.notes')"
                                type="textarea" v-model="client.notes" outer-class="mb-4 w-full" input-class="border input-bordered rounded h-20  px-3 py-2 leading-none focus:border-primary-focus outline-none input border-box w-full mb-1" label-class="font-medium text-sm" help-class="text-xs mb-1 text-gray-600" error-class="text-red-700 text-xs mb-1" />
                            <FormulateInput type="image" name="client logo" :uploader="uploadClientLogo" @file-removed="removeClientLogo()" :value="client.logo_url"  label="Select an image to upload" :help="$t('pages.tenant_client.select_image_file_to_upload')" validation="mime:image/jpeg,image/png,image/svg+xml,image/jpeg" :maxSize="50" :validation-messages="{ mime: $t('pages.tenant_client.client_logo_file_type') }" />
                        </div>

                        <div class="mt-4 flex justify-between max-w-full gap-4">
                            <div class="flex flex-col border flex-1" v-if="clientId">
                                <span class="text-gray-600 text-md bg-gray-100 px-4 py-1.5"> 
                                    {{ $t('pages.tenant_client.packages_assigned_client') }}
                                </span>
                                <div class="flex gap-2 flex-wrap p-4" v-if="clientPackages.length">
                                    <span class="px-2 py-1 text-xs rounded-md font-normal bg-green-100 items-center text-green-800 flex gap-3" v-for="clientPacakge in clientPackages" :key="clientPacakge.id">
                                        {{ clientPacakge.package_name || clientPacakge.id }}
                                        <button class="cursor-pointer bg-white text-xs text-gray-500 hover:bg-primary-focus hover:text-white-text w-6 h-6 flex items-center justify-center rounded-full text-center" @click="removePackage(clientPacakge)">
                                            <font-awesome-icon icon="times" class="fill-current text-current" />
                                        </button>
                                        <!-- <font-awesome-icon @click="removePackage(clientPacakge)" icon="times" /> -->
                                    </span>
                                </div>
                                <span v-else class="text-sm text-gray-400 bg-gray-100 px-3 py-1 rounded-md">
                                    $t('pages.tenant_client.no_packages_assigned_client')
                                </span>
                            </div>
                        </div>

                        <div class="mt-4 flex justify-between max-w-full gap-4 flex-1">
                            <div class="flex flex-col border flex-1" v-if="clientId">
                                <span class="text-gray-600 text-md bg-gray-100 px-4 py-1.5"> 
                                    $t('pages.tenant_client.checks_assigned_client')
                                </span>
                                <div class="flex gap-2 flex-wrap p-4" v-if="assignedChecks.length">
                                    <span class="px-2 py-1 text-xs rounded-md font-normal bg-indigo-100 items-center text-indigo-800 flex gap-3" v-for="clientcheck in assignedChecks" :key="clientcheck.id">
                                        {{ clientcheck.name || clientcheck.id }}
                                        <button class="cursor-pointer bg-white text-xs text-gray-500 hover:bg-primary-focus hover:text-white-text w-6 h-6 flex items-center justify-center rounded-full text-center" @click="unassignChecksToClient(clientcheck)">
                                            <font-awesome-icon icon="times" class="fill-current text-current" />
                                        </button>
                                        <!-- <font-awesome-icoasasn @click="removePackage(clientPacakge)" icon="times" /> -->
                                    </span>
                                </div>
                                <span v-else class="text-sm text-gray-400 bg-gray-100 px-3 py-1 rounded-md">
                                    $t('pages.tenant_client.no_checks_assigned_client')
                                </span>
                            </div>
                        </div>
                        <!-- Work Allocation -->
                        <div v-if="clientId">
                            <workAllocation :assigningWorkAllocation="assigningWorkAllocation" :workAllocationData="workAllocationData" :isLoading="fetchingWorkAllocationList" @selectUser="selectUser($event)" @selectTeam="selectTeam($event)" />
                        </div>

                        <div class="justify-end">
                            <Button :text="clientId ? $t('actions.update') : $t('actions.create')" type="primary" customClass="ml-auto" :loader="clientCreationStarted" @click="submitForm(hasErrors)" />
                        </div>
                    </div>
                </FormulateForm>
            </div>
        </div>

        <!-- delete client packages confirm -->

        <modal-confirm 
            :title="$t('general.are_you_sure')" 
            :message="$t('pages.tenant_client.please_confirm_youre_about_to_delete_the_package')" ref="package-client-remove"></modal-confirm>

        <!-- link client with screening package Modal -->
        <modal-content ref="package-link-modal" :max-width="480" name="packagelink" :title="$t('pages.tenant_client.assign_packages_client')" @close="closePackageLinkModal" :showFooter="false">
            <template #content>
                <div class="h-96 overflow-y-auto">
                    <div v-if="loadingState.fetchingPackagesList" class="flex flex-1 h-full justify-center items-center">
                        <loader />
                    </div>
                    <div v-else class="flex flex-1 flex-col max-h-full overflow-y-auto">
                        <div v-if="filterPackages.length > 0 || searchPackage" class="min-w-full bg-white py-2 shadow-sm">
                            <Input icon="search" :shadow="false" :placeholder="$t('pages.tenant_client.search_packages')" bg="#fff" margin="0" v-model="searchPackage" />
                        </div>

                        <div class="overflow-y-auto scroll-bar scroll-bar" style="min-height: 200px" :class="filterPackages && !filterPackages.length && 'flex items-center justify-center h-full'">
                            <div v-if="filterPackages.length > 0" class="w-full">
                                <div class="" v-for="el in filterPackages" :key="el.id">
                                    <div class="py-0.5 text-sm px-2 flex justify-between items-center border-b-indigo-600 mt-1 border-b border-gray-200">
                                        <div class="flex items-center truncate">
                                            <loader :size="4" v-if="isPackageLinking.loading && isPackageLinking.id === el.id" />
                                            <label class="truncate" :for="el.id">
                                                {{ el.package_name || el.id }}
                                            </label>
                                        </div>
                                        <div>
                                            <Button :disabled="isPackageLinking.loading" text="Add" type="primary" class="btn-xs" @click="handleLinkPackage(el)"></Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-else class="min-h-full flex flex-1 items-center justify-center">
                                <Empty :text="$t('pages.tenant_client.no_packages')" />
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </modal-content>

        <!-- Check Modal -->
        <modal-content ref="check-link-modal" :max-width="480" name="checklinkmodal" :title="$t('pages.tenant_client.assign_checks_client')" @close="closeCheckLinkModal" :showFooter="false">
            <template #content>
                <div class="h-96 overflow-y-auto">
                    <div class="flex justify-center items-center" v-if="loadingState.fetchingChecksList" style="min-height: 300px">
                        <loader />
                    </div>
                    <div v-else class="flex flex-1 flex-col max-h-full overflow-y-auto">
                        <div v-if="filterChecks.length > 0 || searchChecks" class="min-w-full bg-white py-2 shadow-sm">
                            <Input icon="search" :shadow="false" :placeholder="$t('pages.tenant_client.search_checks')" bg="#fff" margin="0" v-model="searchChecks" />
                        </div>
                        <div class="overflow-y-auto scroll-bar" style="min-height: 200px" :class="filterChecks && !filterChecks.length && 'flex items-center justify-center h-full'">
                            <div v-if="filterChecks.length > 0" class="w-full">
                                <div class="" v-for="el in filterChecks" :key="el.id">
                                    <div class="py-0.5 text-sm px-2 flex justify-between items-center border-b-indigo-600 mt-1 border-b border-gray-200">
                                        <div class="flex flex-1 truncate items-center">
                                            <loader :size="4" v-if="isPackageLinking.loading && isPackageLinking.id === el.id" />
                                            <label class="truncate" :for="el.id">
                                                {{ el.name || el.id }}
                                            </label>
                                        </div>
                                        <div class="flex-shrink-0">
                                            <Button :disabled="isPackageLinking.loading" class="btn-xs" text="Add" type="primary" @click="handleLinkCheck(el)"></Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-else>
                                <Empty :text="$t('pages.tenant_client.no_checks')" />
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </modal-content>

        <modal-confirm :title="$t('general.are_you_sure')" :message="$t('pages.tenant_client.please_confirm_youre_about_to_delete_the_field')" ref="confirm-popup"> </modal-confirm>
    </div>
</template>

<script>
import axios from "@/axios";
// import loader from "@shared/loader";
import Button from "@/components/button";
import SubHeader from "@/components/SubHeader";
import modalContent from "@/components/modal-content";
import workAllocation from "@/components/create-client/workAllocation.vue";

// validation Library
import loader from "@shared/loader";
import Input from "@/components/input";
import Empty from "@shared/empty";
import { getChecksList } from "@/modules/checks/services.js";

const modalConfirm = () => import("@/components/modal-confirm");
export default {
    name: "create-client",
    components: {
        Button,
        SubHeader,
        modalContent,
        loader,
        modalConfirm,
        Empty,
        Input,
        workAllocation,
        // axios,
        // loader,
    },
    data() {
        return {
            clientCreationStarted: false,
            client: {
                name: "",
                telephone: "",
                vat_registration_no: "",
                registration_no: "",
                retention_period: "",
                primary_contact_no: "",
                max_users: "",
                primary_user_position: "",
                max_candidate: "",
                address: {
                    line_1: "",
                    line_2: "",
                    city: "",
                    zip_code: "",
                    country: "",
                    active: true,
                },
                prefix: "",
                createdAt: "",
                active: true,
                admin: {
                    email: "",
                    user_name: "",
                    active: true,
                    mobile: "",
                },
                logo_url: [],
            },
            isLoading: false,
            clientId: null,
            packages: [], // all packages
            clientPackages: [], //packages assigned to user
            searchPackage: "",
            isPackageLinking: {
                loading: false,
                id: null,
            },
            defaultChekcLists: [], // all checks
            assignedChecks: [], // checks assigned to client
            searchChecks: "",
            isCheckLinking: {
                loading: false,
                id: null,
            },
            fetchingWorkAllocationList: false,
            workAllocationData: {
                teams: null,
                users: null,
            },
            expandAll: false,
            assigningWorkAllocation: {
                team: false,
                user: false,
            },
            loadingState: {
                fetchingChecksList: false,
                fetchingPackagesList: false,
            },
        };
    },

    computed: {
        // filterPackages(){
        //     let searchvalue = this.searchPackage
        //     if(searchvalue){
        //         let new_package = this.packages.filter((ele) =>
        //         ele.package_name.toLowerCase().includes(searchvalue.toLowerCase())
        //         );
        //         console.log(new_package, "New packages")
        //         return new_package;
        //     }else{
        //         let new_packages = []
        //         this.packages.map(element => {
        //             let packageData =  {...element}
        //             let isPackageExist = this.clientPackages.find(el => el.package_id === element.id)
        //             if(isPackageExist){
        //                 packageData = {...packageData, added:true}
        //             }else{
        //                 packageData = {...packageData, added:false}
        //             }
        //             new_packages.push(packageData)
        //         })
        //         return new_packages
        //     }
        // }
        filterPackages: {
            get() {
                let searchvalue = this.searchPackage;
                let newPackages = this.packages.filter((e) => {
                    for (let i of this.clientPackages) {
                        if (i.package_id === e.id) {
                            return false;
                        }
                    }
                    return true;
                });
                if (searchvalue) {
                    let new_package = newPackages.filter((ele) => ele.package_name.toLowerCase().includes(searchvalue.toLowerCase()));
                    return new_package;
                }
                return newPackages;
            },
            set(packages) {
                this.packages = packages;
            },
        },
        filterChecks() {
            let searchvalue = this.searchChecks;
            let newChecksList = this.defaultChekcLists.filter((e) => {
                for (let i of this.assignedChecks) {
                    if (i.id === e.id) {
                        return false;
                    }
                }
                return true;
            });
            if (searchvalue) {
                let new_checks = newChecksList.filter((ele) => ele.name?.toLowerCase().includes(searchvalue.toLowerCase()));
                return new_checks;
            }
            return newChecksList;
        },
    },

    async mounted() {
        this.client.createdAt = new Date().toISOString().substring(0, 10);
        this.clientId = this.$route.params.id;
        if (this.clientId) {
            await this.getClient();
        }
    },
    title: "Create Client",
    methods: {
        async submitForm(hasErrors) {
            // this.$v.$touch();
            if (hasErrors) {
                this.$toast.error(this.$t("pages.tenant_client.please_fill_all_the_required_fields"));
            } else {
                if (this.clientId) {
                    this.updateClient();
                } else {
                    this.createClient();
                }
            }
        },
        async createClient() {
            // const payload = {};
            const url = "/client";
            this.clientCreationStarted = true;
            let payload = {...this.client, logo_url:this.client?.logo_url[0]?.url || null}

            try {
                // this.client.address.active = (this.client.address.zip_code !== '' && this.client.address.zip_code !== '' && this.client.address.country !== '') || false
                const { data } = await axios.post(url, payload);
                this.client = data;
                this.clientId = data.client_id;
                this.$toast.success(this.$t("pages.tenant_client.client_creation_success"));
                this.clientCreationStarted = false;
                this.$router.push({
                    name: "Edit Client",
                    params: { id: this.clientId },
                });
                this.getClient(this.clientId);
            } catch (error) {
                this.clientCreationStarted = false;
                this.$toast.error(error.response.data.detail || this.$t("pages.tenant_client.client_creation_failed"));
            }
            this.clientCreationStarted = false;
            this.$forceUpdate();
        },

        // Get Client Data

        async getClient(client_id) {
            this.isLoading = true;
            this.client = null;
            this.fetchingWorkAllocationList = true;
            const url = `/client/${client_id || this.clientId}`;
            try {
                const data = await axios.get(url);
                if (data && data.data) {
                    this.client = {
                        ...data.data,
                        logo_url:data.data.logo_url && [{url:data.data.logo_url}] || null,
                        createdAt: new Date(data.data.created_at).toISOString().substring(0, 10),
                        admin: {
                            email: data.data.primary_user.email,
                            user_name: data.data.primary_user.user_name || "",
                        },
                    };

                    // this.client['admin']['email'] = data.data.primary_user_email
                    // this.client['admin']['user_name'] = data.data.name
                }
                // await this.getClientAdmins();
                await this.getAllClientPackages();
                this.isLoading = false;

                this.assignedChecks = await this.getChecksAssignedtoClient();
            } catch (error) {
                console.log(error, "[+] Erroro>>>>");
            }
            this.workAllocationData = await this.fetchWorkAllocationList();
            this.fetchingWorkAllocationList = false;
        },

        async getClientAdmins() {
            const url = `/client/${this.clientId}/admins`;
            try {
                const data = await axios.get(url);
                return data.data;
            } catch (error) {
                console.log("error");
            }
        },

        // Update Client Data

        async updateClient() {
            const url = "/client";
            this.clientCreationStarted = true;
            let payload = {...this.client, logo_url:this.client?.logo_url && (this.client?.logo_url[0]?.url || null)}
            try {
                await axios.put(url, payload);
                this.$toast.success(this.$t("pages.tenant_client.client_updation_success"));
                this.clientCreationStarted = false;
            } catch (error) {
                this.clientCreationStarted = false;
                this.$toast.error(error.response.data.detail || this.$t("pages.tenant_client.client_updation_failed"));
            }
            this.clientCreationStarted = false;
        },

        // get packges
        async getPackages() {
            const url = `/screening-package`;
            try {
                const data = await axios.get(url);
                return data.data;
            } catch (error) {
                this.$toast.warning("Packages not Found");
            }
            this.$forceUpdate();
        },

        async handleLinkPackage(packageData) {
            this.isPackageLinking = {
                loading: true,
                id: packageData.id,
            };
            const url = `/screening-package/${packageData.id}/${this.client.id}`;
            try {
                let { data } = await axios.put(url);
                let package_data = {
                    ...packageData,
                    id: data.client_package_id,
                    package_id: packageData.id,
                };
                this.clientPackages.push(package_data);
                this.$toast.success(this.$t("pages.tenant_client.package_linked_with_client"));
            } catch (error) {
                this.$toast.warning(error.response.data.detail || this.$t("pages.tenant_client.error_assign_package_client"));
            }

            this.isPackageLinking = {
                loading: false,
                id: null,
            };
        },

        // link checks
        async handleLinkCheck(checkData) {
            this.isPackageLinking = {
                loading: true,
                id: checkData.id,
            };
            const url = `/tenant-check/${checkData.id}/assign-to-client`;
            let payload = {
                client_id: this.client.id,
            };
            try {
                let { data } = await axios.post(url, payload);
                // let package_data = {
                //     ...checkData,
                //     id:data.client_package_id,
                //     package_id: packageData.id
                // }
                this.assignedChecks.push(checkData);
                this.$toast.success(data.message || this.$t("pages.tenant_client.success_assigned_to_client"));
            } catch (error) {
                this.$toast.warning(error.response.data.detail || this.$t("pages.tenant_client.error_assign_check_client"));
            }

            this.isPackageLinking = {
                loading: false,
                id: null,
            };
        },

        async getChecksAssignedtoClient() {
            const url = `/tenant-check/client-checks/${this.client.id}`;
            try {
                let { data } = await axios.get(url);
                return data.data;
            } catch (error) {
                console.log(error);
            }
        },

        updatePrefix(eve) {
            this.client.prefix = eve.toLowerCase();
        },

        async unassignChecksToClient(check) {
            const promise = await this.$refs["confirm-popup"].show({
                title: this.$t('general.are_you_sure'),
                message: this.$t("pages.tenant_client.unassign_check_confirmation")
            });
            if (promise) {
                const url = `/tenant-check/${check.id}/unassign-from-client`;
                let payload = {
                    client_id: this.client.id,
                };
                try {
                    let { data } = await axios.post(url, payload);
                    this.assignedChecks = this.assignedChecks.filter((el) => el.id !== check.id);
                    this.$toast.success(data.message);
                } catch (error) {
                    this.$toast.warning(error.response.data.detail || this.$t("pages.tenant_client.error_unassign_check_client"));
                }
            }
            this.$refs["confirm-popup"].close();
        },

        // End

        // getClientPackage

        async getClientpackageInfo(packageId) {
            const url = `/screening-package/${packageId}`;
            try {
                let { data } = await axios.get(url);
                return data;
            } catch (error) {
                console.log(error);
            }
        },
        // get all Client package list
        async getAllClientPackages() {
            const url = `/screening-package/client/${this.client.id}`;
            try {
                let { data } = await axios.get(url);
                this.clientPackages = data;
            } catch (error) {
                console.log("error>>", error);
            }
        },

        // handle remove packge from client

        async removePackage(packageData) {
            const promise = await this.$refs["confirm-popup"].show({
                title: this.$t('general.are_you_sure'),
                message: this.$t('pages.tenant_client.unassign_package_confirmation'),
            });
            if (promise) {
                this.isPackageLinking = {
                    loading: true,
                    id: packageData.id,
                };
                const url = `/screening-package/${packageData.package_id}/${this.client.id}`;
                try {
                    let { data } = await axios.delete(url);
                    this.clientPackages = this.clientPackages.filter((el) => el.package_id !== data.package_id);
                    this.$toast.success(data.detail);
                } catch (error) {
                    this.$toast.warning(error.response.data.detail || this.$t("pages.tenant_client.error_unassign__package_client"));
                }
                this.isPackageLinking = {
                    loading: false,
                    id: null,
                };
            }
            this.$refs["confirm-popup"].close();
        },

        // package link Modal control
        closePackageLinkModal() {
            this.$refs["package-link-modal"].hideModal();
        },

        async showPackageLinkModal() {
            this.loadingState.fetchingPackagesList = true;
            this.$refs["package-link-modal"].showModal();
            this.packages = await this.getPackages();
            this.loadingState.fetchingPackagesList = false;
        },

        // End Package Link Modal control

        // Checks Link Modal control
        closeCheckLinkModal() {
            this.$refs["check-link-modal"].hideModal();
        },

        async showCheckLinkModal() {
            this.loadingState.fetchingChecksList = true;
            this.$refs["check-link-modal"].showModal();
            this.defaultChekcLists = await getChecksList(this.$store.getters.getTenantId);
            this.loadingState.fetchingChecksList = false;
        },

        // End Cehcks link Modal control

        // Work ALlocation
        async fetchWorkAllocationList() {
            let workAllocationData = {
                users: await this.fetchUsersList(),
                teams: await this.fetchTeamsList(),
            };
            return workAllocationData;
        },
        async fetchUsersList() {
            let url = `work-allocation/users/tenant?client_id=${this.clientId}`;
            let users = [];
            try {
                const { data } = await axios.get(url);
                users = data;
            } catch (error) {
                console.log(error, "Error");
            }
            return users;
        },
        async fetchTeamsList() {
            let url = `work-allocation/groups/tenant?client_id=${this.clientId}`;
            let teams = [];
            try {
                const { data } = await axios.get(url);
                teams = data;
            } catch (error) {
                console.log(error, "Error");
            }
            return teams;
        },

        async updateTeamAllocation(team) {
            let url = `work-allocation/client/${this.clientId}/group/${team.id}/tenant`;
            try {
                const { data } = await axios.post(url);
                this.prefetchedworkAllocationData = this.workAllocationData;
                this.$toast.success(data.message || this.$t("pages.tenant_client.success_team_assigned_to_client"));
            } catch (error) {
                this.$toast.error(error.response.data.detail || this.$t("pages.tenant_client.error_team_assigned_to_client"));
            }
        },

        async removeTeamAllocation(team) {
            let url = `work-allocation/client/${this.clientId}/group/${team.id}/tenant`;
            try {
                const { data } = await axios.delete(url);
                this.prefetchedworkAllocationData = this.workAllocationData;
                this.$toast.success(data.message || this.$t("pages.tenant_client.success_team_removed_client"));
            } catch (error) {
                this.$toast.error(error.response.data.detail || this.$t("pages.tenant_client.error_team_removed_client"));
            }
        },

        async updateUserAllocation(user) {
            let url = `work-allocation/client/${this.clientId}/user/${user.id}/tenant`;
            try {
                const { data } = await axios.post(url);
                this.prefetchedworkAllocationData = this.workAllocationData;
                this.$toast.success(data.message || this.$t("pages.tenant_client.success_user_assigned_to_client"));
            } catch (error) {
                this.$toast.error(error.response.data.detail || this.$t("pages.tenant_client.error_user_assigned_to_client"));
            }
        },

        async removeUserAllocation(user) {
            let url = `work-allocation/client/${this.clientId}/user/${user.id}/tenant`;
            try {
                const { data } = await axios.delete(url);
                this.prefetchedworkAllocationData = this.workAllocationData;
                this.$toast.success(data.message || this.$t("pages.tenant_client.success_user_removed_client"));
            } catch (error) {
                this.$toast.error(error.response.data.detail || this.$t("pages.tenant_client.error_user_removed_client"));
            }
        },

        async selectTeam(team) {
            this.assigningWorkAllocation.team = true;
            if (team.allotted) {
                await this.updateTeamAllocation(team);
            } else {
                await this.removeTeamAllocation(team);
            }
            this.assigningWorkAllocation.team = false;
        },

        async selectUser(user) {
            this.assigningWorkAllocation.user = true;
            if (user.allotted) {
                await this.updateUserAllocation(user);
            } else {
                await this.removeUserAllocation(user);
            }
            this.assigningWorkAllocation.user = false;
        },
        async uploadClientLogo(file, progress){
            // console.log(file, "file")
            if(file){
                let allowedFiles = ['image/png', 'image/svg+xml', 'image/png', 'image/jpeg']
                if(!allowedFiles.includes(file.type)) return  this.$toast.error(this.$t('pages.tenant_client.error_only_svg_png_jpg_jpeg'))

            }
            if ((file.size / 1e6) > 50){
                this.client.logo_url = []
                return this.$toast.error(this.$t('pages.tenant_client.file_size_should_be_less_than_50_mb'))
            }
            progress(0)
            let url = `/client/logo`
            let formData = new FormData();
            formData.append('file', file)
            try{
                let {data} = await axios.post(url, formData)
                this.client = {...this.client, logo_url:[{url:data?.data?.logo_url}]}
                progress(100)
            }catch(error){
                console.log(error, "error")
            }
            return Promise.resolve({}) 
        },
        async removeClientLogo(){
            if(!this.clientId){
                let url = `/client/logo`
                let payload = {
                    logo_url: this.client?.logo_url[0]?.url,
                }
                await axios.delete(url, {data:payload} )
            }
            this.client = {...this.client, logo_url:null}
        }
        // End Workallocation
    },
};
</script>

<style scoped lang="scss">
.form-group--error {
    border: 1px solid rgb(212, 92, 92) !important;
}
.formulate-input {
    font-family: "Poppins", sans-serif !important;
    .formulate-input-element {
        max-width: 100% !important;
    }
}
</style>
